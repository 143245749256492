import { getBMI } from '../../../../src/utils/numberUtils';
import {
  EligibilityCriteria,
  EligibilityResult,
  checkForExcludedPregnanacyAnswers,
  reduceEligibilityCriteria,
} from '../../../components/Quiz/QuizUtils';
import { DiabetesStatusType, OldQuizAnswersType } from '../../../types/quiz';
import {
  GLP1_ELIGIBILITY_CRITERIA,
  meetsGLP1AgeCriteria,
  meetsGLP1BMICriteria,
} from '../baseEligibility';

export const meetsGLP1DiabetesCriteriaForOldQuizFlow = (
  diabetesStatus: DiabetesStatusType,
): boolean => {
  if (diabetesStatus === 'type1' || diabetesStatus === 'type2') {
    return false;
  }
  return true;
};

/**
 * Returns the base criteria for GLP1 eligibility before injecting BMI criteria
 * for users either with or without a comorbidity
 *
 * @param {Object} answers - The user's answers to the old quiz questions.
 * @param {number} answers.age - The user's age.
 * @param {number} answers.weightKg - The user's weight in kilograms.
 * @param {number} answers.heightCm - The user's height in centimeters.
 * @param {string} answers.gender - The user's gender.
 * @param {string} answers.pregnancy - The user's pregnancy status.
 * @param {Array} answers.healthConditions - The user's health conditions.
 * @param {string} answers.diabetesStatus - The user's diabetes status.
 * @param {string} locale - The active locale.
 * @returns {Array} An array of eligibility criteria, each with a key, value, and validity status.
 */
export const getGLP1BaseCriteriaForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    | 'age'
    | 'weightKg'
    | 'heightCm'
    | 'gender'
    | 'pregnancy'
    | 'healthConditions'
    | 'diabetesStatus'
  >,
  locale?: string,
): EligibilityCriteria[] => {
  const {
    age,
    weightKg,
    heightCm,
    pregnancy,
    healthConditions,
    diabetesStatus,
    gender,
  } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  return [
    {
      key: 'age',
      value: age,
      isValid: !(age === undefined || !meetsGLP1AgeCriteria(age)),
    },
    {
      key: 'heightCm',
      value: heightCm,
      isValid: heightCm !== undefined,
    },
    {
      key: 'weightKg',
      value: weightKg,
      isValid: weightKg !== undefined,
    },
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteria(bmi),
    },
    { key: 'gender', value: gender, isValid: !!gender },
    {
      key: 'pregnancy',
      value: pregnancy,
      isValid: !(
        gender === 'female' &&
        checkForExcludedPregnanacyAnswers(
          GLP1_ELIGIBILITY_CRITERIA.pregnancy.excluded,
          pregnancy,
        )
      ),
    },
    {
      key: 'diabetesStatus',
      value: diabetesStatus,
      isValid: !(
        diabetesStatus === undefined ||
        !meetsGLP1DiabetesCriteriaForOldQuizFlow(diabetesStatus)
      ),
    },
    {
      key: 'healthConditions',
      value: healthConditions,
      isValid: !(
        healthConditions === undefined ||
        healthConditions.some(condition =>
          GLP1_ELIGIBILITY_CRITERIA.healthConditions.excluded.includes(
            condition,
          ),
        )
      ),
    },
    {
      key: 'locale',
      value: locale,
      isValid: GLP1_ELIGIBILITY_CRITERIA.locale.included.includes(locale),
    },
  ];
};

/**
 * Determines whether a user qualifies for the GLP1 programme, taking all their screening
 * answers into account.
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeWithoutExtendedEligibilityForOldQuizFlow =
  (
    answers: Pick<OldQuizAnswersType, 'weightKg' | 'heightCm'>,
    locale?: string,
  ): EligibilityResult => {
    const { weightKg, heightCm } = answers;

    const bmi = getBMI(weightKg || 0, heightCm || 0);

    const criteria: EligibilityCriteria[] = [
      ...getGLP1BaseCriteriaForOldQuizFlow(answers, locale),
      {
        key: 'bmi',
        value: bmi,
        isValid: meetsGLP1BMICriteria(bmi),
      },
    ];

    return reduceEligibilityCriteria(criteria);
  };
