import { getBMI } from '../../../src/utils/numberUtils';
import {
  EligibilityCriteria,
  EligibilityResult,
  qualifiesForWeightLossProgramme,
  reduceEligibilityCriteria,
} from '../../components/Quiz/QuizUtils';
// Don't use an aliased import here, or Cypress breaks
import { getGetPlanResultsCookie } from '../../nextUtils/trackingUtils';
import { getGLP1BaseCriteria } from './baseEligibility';
import {
  getWeightRelatedComorbidities,
  meetsGLP1BMICriteriaWithComorbidity,
} from './comorbidityEligibility';
import { qualifiesForGLP1Programme } from './extendedEligibility';
import { getLocalStorage } from '../../components/NhsSignup/helpers';
import { QuizStateType, initialState } from '../../components/Quiz/state/state';
import { qualifiesForGLP1ProgrammeForOldQuizFlow } from './oldQuiz/extendedEligibility';

/**
 * Get a user's eligibility for the GLP-1 programme based on their quiz answers
 * stored in the 'get-plan-results' cookie.
 *
 * This function checks eligibility based on both the old and new quiz flows.
 * For the old quiz flow, it retrieves the user's answers from the 'get-plan-results' cookie
 * and checks if the user is eligible for the GLP-1 programme and if they are open to medication.
 *
 * For the new quiz flow, it retrieves the user's answers from the local storage under the 'cached-quiz' key
 * and checks if the user is eligible for the GLP-1 programme and if they are open to medication.
 *
 * If the user is eligible based on either the old or new quiz flow, the function returns true.
 *
 * @param {string} [locale] - Locale for the user.
 * @return {boolean} Whether the user is eligible for the GLP-1 programme.
 */
export const getGLP1EligibilityFromCookie = (locale?: string): boolean => {
  const oldQuizAnswers = getGetPlanResultsCookie();
  const glp1Eligibility = qualifiesForGLP1ProgrammeForOldQuizFlow(
    oldQuizAnswers,
    locale,
  );

  const isEligibleBasedOnOldQuiz =
    glp1Eligibility.isEligible &&
    oldQuizAnswers.openToMedication !== 'noMedication';

  const quizFlowAnswers = getLocalStorage('cached-quiz', initialState);
  const glp1EligibilityQuizFlow = qualifiesForGLP1Programme(
    quizFlowAnswers,
    locale,
  );

  const isEligibleBasedOnQuizFlow =
    glp1EligibilityQuizFlow.isEligible &&
    quizFlowAnswers.openToMedication !== 'noMedication';

  /**
   * If a user is eligible on the old flow, but has subsequently completed the new quiz flow and is not eligible on the new flow, then they become ineligible.
   * To determine this, we can check if the user has gone through the new quiz flow, we store a flag in local storage to indicate whether the user has started the new quiz.
   * */
  if (
    isEligibleBasedOnOldQuiz &&
    !isEligibleBasedOnQuizFlow &&
    quizFlowAnswers.hasStartedQuiz === true
  ) {
    return false;
  }

  return isEligibleBasedOnOldQuiz || isEligibleBasedOnQuizFlow;
};

/**
 * Get a user's quiz answers from local storage. Useful when quiz answers need
 * to be accessed outside of the QuizProvider (i.e. at checkout).
 *
 * If the user does not have any quiz answers in local storage, the function
 * returns the initial quiz state.
 *
 * @returns {QuizStateType} User's quiz answers from local storage.
 */
export const getQuizAnswersFromLocalStorage = (): QuizStateType =>
  getLocalStorage('cached-quiz', initialState);

/**
 * Extracts the answer to the openToMedication question from either the old quiz flow or
 * new quiz flow depending on which one the user has gone through
 *
 * The answer to this question specifically is used on the pricing screen
 *
 * @returns {string} key representing the answer to the openToMedication quiz question
 */
export const getIsOpenToMedicationFromQuizAnswers = (): string | undefined => {
  const quizFlowAnswers = getQuizAnswersFromLocalStorage();
  if (quizFlowAnswers && quizFlowAnswers.openToMedication) {
    return quizFlowAnswers.openToMedication;
  }

  const oldQuizAnswers = getGetPlanResultsCookie();
  return oldQuizAnswers.openToMedication;
};

/**
 * Determines whether a user should be shown the Vitality programme question, taking all their screening
 * answers into account. This will be shown if they're eligible for GLP-1 but don't want to inject medication.
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user qualifies for the Vitality programme.
 */
const shouldBeShownVitalityQuestion = (
  answers: Pick<
    QuizStateType,
    'weightKg' | 'heightCm' | 'openToMedication' | 'healthConditions'
  >,
): EligibilityResult => {
  const { weightKg, heightCm, openToMedication, healthConditions } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  const hasDiabetes =
    healthConditions.includes('Type 2 diabetes') ||
    healthConditions.includes('Type 1 diabetes');

  // An array of criteria to determine whether the user qualifies for Vitality
  const criteria: EligibilityCriteria[] = [
    {
      key: 'bmi',
      value: bmi,
      isValid: (!!bmi && bmi >= 30) || (!!bmi && bmi >= 25 && hasDiabetes),
    },
    {
      key: 'openToMedication',
      value: openToMedication,
      isValid: !(
        openToMedication === undefined ||
        openToMedication === 'injectedMedication'
      ),
    },
  ];

  return reduceEligibilityCriteria(criteria);
};

/**
 * Determines whether a user should see the Vitality member question.
 * Should only show if not eligible for GLP1, but with BMI >30.
 *
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user qualifies for GLP1 quiz screens.
 */
export const shouldShowVitalityQuestionGLP1 = (
  answers: Partial<QuizStateType>,
): boolean => {
  const safeAnswers: Pick<
    QuizStateType,
    'weightKg' | 'heightCm' | 'openToMedication' | 'healthConditions'
  > = {
    weightKg: answers.weightKg || 0,
    heightCm: answers.heightCm || 0,
    openToMedication: answers.openToMedication || '',
    healthConditions: answers.healthConditions || [],
  };

  return (
    qualifiesForWeightLossProgramme(answers).isEligible &&
    shouldBeShownVitalityQuestion(safeAnswers).isEligible
  );
};

/**
 * Determines whether a user has answered having a specified health comorbidity. If so, we should
 * show the health comorbidity details question.
 *
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has specified a health comorbidity.
 */
export const shouldNavigateToHealthConditionsComorbiditiesQuestion = (
  answers: Pick<
    QuizStateType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'pregnancy'
    | 'gender'
    | 'diabetesStatus'
  >,
  locale?: string,
): boolean => {
  const { weightKg, heightCm } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  const glp1CriteriaWithoutBMI = getGLP1BaseCriteria(answers, locale).filter(
    criterion => criterion.key !== 'bmi',
  );

  // Criteria used to determine if a user should see the healthConditionsComorbidities question
  // which may enable them to be eligible for the GLP1 programme
  const criteria: EligibilityCriteria[] = [
    ...glp1CriteriaWithoutBMI,
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteriaWithComorbidity(bmi),
    },
  ];

  return reduceEligibilityCriteria(criteria).isEligible;
};

export const shouldNavigateToHealthConditionsComorbiditiesDetailsQuestion = (
  answers: Partial<QuizStateType>,
): boolean => {
  const safeAnswers: Pick<
    QuizStateType,
    'healthConditions' | 'healthConditionsComorbidities'
  > = {
    healthConditions: answers.healthConditions || [],
    healthConditionsComorbidities: answers.healthConditionsComorbidities || [],
  };

  return getWeightRelatedComorbidities(safeAnswers).length > 0;
};
