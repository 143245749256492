import { getBMI } from '../../../../src/utils/numberUtils';
import {
  EligibilityCriteria,
  EligibilityResult,
  reduceEligibilityCriteria,
} from '../../../components/Quiz/QuizUtils';
import { OldQuizAnswersType } from '../../../types/quiz';
import { convertAnswerObjectToArray } from '../../quizUtils';
import { getGLP1BaseCriteriaForOldQuizFlow } from './baseEligibility';

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has type 2 diabetes.
 */
export const hasType2DiabetesForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean => answers.diabetesStatus === 'type2';

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is taking diabetes medication.
 */
export const isTakingDiabetesMedicationForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean => !!answers.diabetesManagement?.medication;

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is managing their diabetes without medication.
 */
export const managesDiabetesWithoutMedicationForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean =>
  !isTakingDiabetesMedicationForOldQuizFlow(answers) &&
  Object.entries(answers.diabetesManagement || {})
    .filter(([answer]) => !['none', 'medication'].includes(answer))
    .some(([, value]) => !!value);

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is managing their diabetes with **only** metformin.
 */
export const takesMetforminExclusivelyForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean => {
  const { metformin, ...otherMedication } =
    answers?.type2DiabetesMedication || {};
  return !!metformin && Object.values(otherMedication).every(value => !value);
};

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user's diabetes medication was not prescribed by a specialist.
 */
export const diabetesMedicationNotPrescribedBySpecialistForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean =>
  !!answers?.diabetesMedicationPrescriber &&
  answers.diabetesMedicationPrescriber !== 'specialist';

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has no upcoming diabetes appointments.
 */
export const noUpcomingDiabetesAppointmentForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean => answers.hasDiabetesDoctorsAppt === false;

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is not ineligible for the GLP-1 programme based on the diabetes medication that they're taking.
 */
export const meetsDiabetesMedicationCriteriaForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean =>
  isTakingDiabetesMedicationForOldQuizFlow(answers) &&
  takesMetforminExclusivelyForOldQuizFlow(answers) &&
  diabetesMedicationNotPrescribedBySpecialistForOldQuizFlow(answers);

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has no diabetes complications.
 */
export const noDiabetesComplicationsForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): boolean => {
  const { none, ...otherComplications } = answers?.diabetesComplications || {};
  return !!none && Object.values(otherComplications).every(value => !value);
};

/**
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is eligible for the GLP-1 programme based on their diabetes status and medication.
 */
const getType2DiabetesLifestyleCriteriaForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): EligibilityCriteria[] => {
  const {
    diabetesStatus,
    diabetesManagement = {},
    hasDiabetesDoctorsAppt,
    diabetesComplications = {},
  } = answers;

  return [
    {
      key: 'diabetesStatus',
      value: diabetesStatus,
      isValid: hasType2DiabetesForOldQuizFlow(answers),
    },
    {
      key: 'hasDiabetesDoctorsAppt',
      value: hasDiabetesDoctorsAppt,
      isValid: noUpcomingDiabetesAppointmentForOldQuizFlow(answers),
    },
    {
      key: 'diabetesComplications',
      value: convertAnswerObjectToArray(diabetesComplications),
      isValid: noDiabetesComplicationsForOldQuizFlow(answers),
    },
    {
      key: 'diabetesManagement',
      value: convertAnswerObjectToArray(diabetesManagement),
      isValid: managesDiabetesWithoutMedicationForOldQuizFlow(answers),
    },
  ];
};

/**
 * Determines whether a user qualifies for the GLP1 programme despite having type 2 diabetes.
 *
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
const getType2DiabetesMedicationCriteriaForOldQuizFlow = (
  answers: Partial<OldQuizAnswersType>,
): EligibilityCriteria[] => {
  const {
    diabetesStatus,
    diabetesManagement = {},
    type2DiabetesMedication = {},
    diabetesMedicationPrescriber,
    hasDiabetesDoctorsAppt,
    diabetesComplications = {},
  } = answers;

  return [
    {
      key: 'diabetesStatus',
      value: diabetesStatus,
      isValid: hasType2DiabetesForOldQuizFlow(answers),
    },
    {
      key: 'hasDiabetesDoctorsAppt',
      value: hasDiabetesDoctorsAppt,
      isValid: noUpcomingDiabetesAppointmentForOldQuizFlow(answers),
    },
    {
      key: 'diabetesComplications',
      value: convertAnswerObjectToArray(diabetesComplications),
      isValid: noDiabetesComplicationsForOldQuizFlow(answers),
    },
    {
      key: 'diabetesManagement',
      value: convertAnswerObjectToArray(diabetesManagement),
      isValid: isTakingDiabetesMedicationForOldQuizFlow(answers),
    },
    {
      key: 'type2DiabetesMedication',
      value: convertAnswerObjectToArray(type2DiabetesMedication),
      isValid: takesMetforminExclusivelyForOldQuizFlow(answers),
    },
    {
      key: 'diabetesMedicationPrescriber',
      value: diabetesMedicationPrescriber,
      isValid:
        diabetesMedicationNotPrescribedBySpecialistForOldQuizFlow(answers),
    },
  ];
};

/**
 * Determines whether a user qualifies for the GLP1 programme despite having type 2 diabetes.
 *
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeWithType2DiabetesForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
    | 'diabetesStatus'
  >,
  locale?: string,
): EligibilityResult => {
  /**
   * The type 2 diabetes criteria is the same as the base GLP1 criteria, except
   * we remove the requirement on the "I don't have diabetes or a family
   * history of diabetes" answer.
   * Instead, we allow a user to answer with "type 2 diabetes", if they meet
   * certain conditions for how they're managing their condition - i.e either
   * they manage it without medication, or they manage it with metformin.
   */
  const glp1CriteriaWithoutDiabetesStatusOrBmi =
    getGLP1BaseCriteriaForOldQuizFlow(answers, locale).filter(
      criterion =>
        criterion.key !== 'diabetesStatus' && criterion.key !== 'bmi',
    );

  // For eligibility on the GLP1 programme, users can have a BMI over 27
  // instead of over 30
  const bmi = getBMI(answers.weightKg || 0, answers.heightCm || 0);
  const glp1CriteriaWithUpdatedBmi = [
    ...glp1CriteriaWithoutDiabetesStatusOrBmi,
    {
      key: 'bmi',
      value: bmi,
      isValid: !!bmi && bmi >= 27,
    },
  ];

  const type2DiabetesLifestyleEligibility = reduceEligibilityCriteria([
    ...glp1CriteriaWithUpdatedBmi,
    ...getType2DiabetesLifestyleCriteriaForOldQuizFlow(answers),
  ]);

  const type2DiabetesMedicationEligibility = reduceEligibilityCriteria([
    ...glp1CriteriaWithUpdatedBmi,
    ...getType2DiabetesMedicationCriteriaForOldQuizFlow(answers),
  ]);

  if (managesDiabetesWithoutMedicationForOldQuizFlow(answers)) {
    return type2DiabetesLifestyleEligibility;
  }

  return type2DiabetesMedicationEligibility;
};
