import { countryCodePricingMap } from '.';

export const EUROPEAN_COUNTRY_CODES = [
  'BE',
  'GB',
  'IE',
  'DK',
  'FR',
  'DE',
  'NL',
  'ES',
  'SE',
  'FI',
  'NO',
  'IS',
] as const;

const ANZ_COUNTRY_CODES = ['AU', 'NZ'] as const;

const NORTH_AMERICAN_COUNTRY_CODES = ['US', 'CA'] as const;

const UNKNOWN_COUNTRY_CODES = ['Unknown'] as const;

export const REGIONS = [
  {
    name: 'anz',
    readableName: 'Asia/Pacific',
    countryCodes: ANZ_COUNTRY_CODES,
  },
  {
    name: 'europe',
    readableName: 'Europe',
    countryCodes: EUROPEAN_COUNTRY_CODES,
  },
  {
    name: 'north-america',
    readableName: 'North America',
    countryCodes: NORTH_AMERICAN_COUNTRY_CODES,
  },
  {
    name: 'unknown',
    readableName: 'Unknown',
    countryCodes: UNKNOWN_COUNTRY_CODES,
  },
] as const;

export const REGION_NAMES = REGIONS.map(region => region.name);

export type RegionType = typeof REGION_NAMES[number];
export type CountryCodeType = typeof REGIONS[number]['countryCodes'][number];
export type CountryCodeWithoutUnknownType = Exclude<CountryCodeType, 'Unknown'>;

export const SUBREGION_TYPE = ['east', 'west'] as const;
export type SubregionType = typeof SUBREGION_TYPE[number];

export const NEXTJS_LOCALES = [
  'uk',
  'au',
  'nz',
  'dk',
  'ie',
  'be',
  'fr',
  'nl',
  'de',
  'es',
  'se',
  'fi',
  'no',
  'is',
  'us',
  'ca',
];
export const NEXTJS_DEFAULT_LOCALE = 'uk';
export type LocaleType = typeof NEXTJS_LOCALES[number];

const COUNTRY_TO_LOCALE: Record<CountryCodeWithoutUnknownType, LocaleType> = {
  GB: 'uk',
  AU: 'au',
  NZ: 'nz',
  DK: 'dk',
  IE: 'ie',
  BE: 'be',
  FR: 'fr',
  NL: 'nl',
  DE: 'de',
  ES: 'es',
  SE: 'se',
  FI: 'fi',
  NO: 'no',
  IS: 'is',
  US: 'us',
  CA: 'ca',
} as const;

export const getCountryCodeFromLocale = (
  locale: string | undefined,
): CountryCodeWithoutUnknownType =>
  (Object.keys(COUNTRY_TO_LOCALE) as CountryCodeWithoutUnknownType[]).find(
    country => COUNTRY_TO_LOCALE[country] === locale,
  ) || 'GB';

export const NEXTJS_LOCALES_TO_LANG: Record<LocaleType, string> = {
  uk: 'en-GB',
  au: 'en-AU',
  nz: 'en-NZ',
  dk: 'da-DK',
  ie: 'en-IE',
  be: 'fr-BE', // n.b. Ideally, we would also map 'nl-BE' for dutch speaking Belgian users
  fr: 'fr-FR',
  nl: 'nl-NL',
  de: 'de-DE',
  es: 'es-ES',
  se: 'sv-SE',
  fi: 'fi-FI',
  no: 'no-NO',
  is: 'is-IS',
  us: 'en-US',
  ca: 'en-CA',
} as const;

export const getLocaleFromCountryCode = (
  countryCode: string,
): LocaleType | undefined =>
  COUNTRY_TO_LOCALE[countryCode as CountryCodeWithoutUnknownType];

export type EmailsWithTranslationType =
  | 'mentorReminderThreeDays'
  | 'welcome'
  | 'restartProcessed'
  | 'scheduledToCancel'
  | 'welcomeConsumer'
  | 'welcomeConsumerv2';
// For now, as we're only translating emails into
// American English, use 'US' | 'GB' instead of CountryCodeWithoutUnknownType
// as it keeps the Record cleaner and easier to read
export const emailsWithTranslations: Record<
  EmailsWithTranslationType,
  Record<'US' | 'GB', Record<string, string>>
> = {
  mentorReminderThreeDays: {
    GB: {
      emailTemplate: 'mentorReminderThreeDays',
      subject: `Your Second Nature programme starts in 3 days!`,
    },
    US: {
      emailTemplate: 'mentorReminderThreeDaysUS',
      subject: `Your Second Nature program starts in 3 days!`,
    },
  },
  restartProcessed: {
    GB: {
      emailTemplate: 'restartProcessed',
      subject: `Welcome back to Second Nature Core`,
    },
    US: {
      emailTemplate: 'restartProcessedUS',
      subject: `Welcome back to Second Nature Core`,
    },
  },
  scheduledToCancel: {
    GB: {
      emailTemplate: 'scheduledToCancel',
      subject: `We're sorry to see you go!`,
    },
    US: {
      emailTemplate: 'scheduledToCancelUS',
      subject: `We're sorry to see you go!`,
    },
  },
  welcome: {
    GB: {
      emailTemplate: 'welcome',
      subject: `Second Nature - You're all set!`,
    },
    US: {
      emailTemplate: 'welcomeUS',
      subject: `Second Nature - You're all set!`,
    },
  },
  welcomeConsumer: {
    GB: {
      emailTemplate: 'welcomeConsumer',
      subject: 'Welcome to Second Nature!',
    },
    US: {
      emailTemplate: 'welcomeConsumerUS',
      subject: 'Welcome to Second Nature!',
    },
  },
  welcomeConsumerv2: {
    GB: {
      emailTemplate: 'welcomeConsumerv2',
      subject: 'Welcome to Second Nature!',
    },
    US: {
      emailTemplate: 'welcomeConsumerv2US',
      subject: 'Welcome to Second Nature!',
    },
  },
};

// The primary US time zones from east to west
//
// NB For simplicity we are using standardised time zones - e.g. Eastern Time Zone (ET), instead of
// distinguishing between Eastern Standard Time (EST) and Eastern Daylight Time (EDT)
// NB quite a few states have multiple time zones, so for simplicity we're using the larger/more populous part
// of the state as the timezone for the whole state
//
// See:
// https://en.wikipedia.org/wiki/Time_in_the_United_States#United_States_and_regional_time_zones
// https://simple.wikipedia.org/wiki/List_of_U.S._states_and_territories_by_time_zone
// https://www.countries-ofthe-world.com/time-zones-usa.html
//
export const US_TIME_ZONES_AND_STATES: readonly {
  name: string;
  readableName: string;
  tzDatabaseName: string; // see: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  states: readonly string[];
}[] = [
  {
    name: 'AT',
    readableName: 'Atlantic Time Zone',
    tzDatabaseName: 'America/Puerto_Rico',
    states: ['Puerto Rico', 'Virgin Island'],
  },
  {
    name: 'ET',
    readableName: 'Eastern Time Zone',
    tzDatabaseName: 'America/New_York',
    states: [
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Florida',
      'Georgia',
      'Indiana',
      'Kentucky',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'New Hampshire',
      'New Jersey',
      'New York',
      'North Carolina',
      'Ohio',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'Vermont',
      'Virginia',
      'West Virginia',
    ],
  },
  {
    name: 'CT',
    readableName: 'Central Time Zone',
    tzDatabaseName: 'America/Chicago',
    states: [
      'Alabama',
      'Arkansas',
      'Illinois',
      'Iowa',
      'Kansas',
      'Louisiana',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Nebraska',
      'North Dakota',
      'Oklahoma',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Wisconsin',
    ],
  },
  {
    name: 'MT',
    readableName: 'Mountain Time Zone',
    // Although Phoenix, Arizona is the most populous MT city in the TZ database, Phoenix doesn't observe DST
    // whereas Denver, Colorado and the rest of the MT zone does, so use Denver as the best approximation
    // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    tzDatabaseName: 'America/Denver',
    states: [
      'Arizona',
      'Colorado',
      'Idaho',
      'Montana',
      'New Mexico',
      'Utah',
      'Wyoming',
    ],
  },
  {
    name: 'PT',
    readableName: 'Pacific Time Zone',
    tzDatabaseName: 'America/Los_Angeles',
    states: ['California', 'Nevada', 'Oregon', 'Washington'],
  },
  {
    name: 'AKT',
    readableName: 'Alaska Time Zone',
    tzDatabaseName: 'America/Anchorage',
    states: ['Alaska'],
  },
  {
    name: 'HAT',
    readableName: 'Hawaii-Aleutian Time Zone',
    tzDatabaseName: 'Pacific/Honolulu',
    states: ['Hawaii'],
  },
  {
    name: 'SST',
    readableName: 'Samoa Time Zone',
    tzDatabaseName: 'Pacific/Pago_Pago', // capital of American Samoa
    states: ['American Samoa'],
  },
  {
    name: 'MHT',
    readableName: 'Marshall Islands Time',
    tzDatabaseName: 'Pacific/Majuro', // capital of Marshall Islands
    states: ['Marshall Islands'],
  },
  {
    name: 'ChST', // NB officially this has a lowercase h: https://en.wikipedia.org/wiki/Chamorro_Time_Zone
    readableName: 'Chamorro Time Zone',
    tzDatabaseName: 'Pacific/Guam',
    states: [
      'Federated States of Micronesia',
      'Guam',
      'Northern Mariana Islands',
    ],
  },
  {
    name: 'PWT',
    readableName: 'Palau Time Zone',
    tzDatabaseName: 'Pacific/Palau',
    states: ['Palau'],
  },
] as const;

export const isCountryCodeValid = (
  countryCode: string,
): countryCode is CountryCodeWithoutUnknownType =>
  Object.prototype.hasOwnProperty.call(countryCodePricingMap, countryCode) &&
  countryCode !== 'Unknown';
