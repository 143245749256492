import { emailRegex } from '../constants';
import {
  NEXTJS_DEFAULT_LOCALE,
  NEXTJS_LOCALES,
} from '../constants/internationalConstants';
import { convertKgToImperial, isNumeric } from './numberUtils';

export const toProper = (string: string | undefined): string | undefined => {
  if (!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toProperLowercase = (
  string: string | undefined,
): string | undefined => {
  if (!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitaliseEachWord = (
  string: string | undefined,
): string | undefined => {
  if (!string) {
    return undefined;
  }
  return string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

// If text is above a limit, add ellipsis
export const truncateText = (text: string, limit: number): string =>
  text && text.length > limit ? `${text.substr(0, limit - 1)}...` : text;

const ukMobileRegex = /^07\d{9}$/;
export const isValidMobileNumber = (number: string): boolean => {
  if (!number || number.length === 0) {
    return false;
  }

  const ukPhoneNumber = number
    .toString()
    .replace(/\s+/g, '')
    .replace('+44', '0');

  return ukMobileRegex.test(ukPhoneNumber);
};

export const formatUKMobileNumber = (number: string): string =>
  number.replace(/\s+/gm, '').replace(/^(?:\+?44|0)?/gm, '+44');

export const isValidEmail = (email: string | undefined): boolean => {
  if (!email) {
    return false;
  }
  const lowerCaseTrimmedEmail = email.toLowerCase().trim();
  return emailRegex.test(lowerCaseTrimmedEmail);
};

const angularRegex = /{\[{.*?}]}/;

/**
 * With our programme content, we use Angular template formatting, like '{[{ firstName }]}'
 * We need to strip this out before passing to the translation engine
 */
export const findAndReplaceAngularTemplateStrings = (
  text: string,
): {
  result: string;
  foundValues: string[];
} => {
  let result = text;

  let count = 0;

  const foundValues: string[] = [];

  while (angularRegex.test(result)) {
    result = result.replace(angularRegex, value => {
      foundValues.push(value);
      return `<span class="notranslate">$${count}</span>`;
    });
    count += 1;
  }

  return {
    result,
    foundValues,
  };
};

/**
 * Adds the Angular template bracketing back in after they have been removed
 */
export const addAngularTemplatesInToString = (
  text: string,
  foundValues: string[],
): string => {
  let result = text;

  for (let i = 0; i < foundValues.length; i++) {
    result = result.replace(
      `<span class="notranslate">$${i}</span>`,
      foundValues[i],
    );
  }
  return result;
};

export const displayWeight = (
  weightKg: number,
  weightUnit: 'kg' | 'lbs' | 'st',
): string => {
  if (!isNumeric(weightKg)) {
    return '';
  }

  const imperialWeights = convertKgToImperial(weightKg) as {
    weightLbs: number;
    weightSt: number;
    weightLbsRemainder: number;
  };

  switch (weightUnit) {
    case 'kg':
      return `${Math.round(weightKg * 10) / 10} kg`;
    case 'lbs':
      return `${imperialWeights.weightLbs} lbs`;
    case 'st':
      if (imperialWeights.weightSt === 0) {
        return `${imperialWeights.weightLbsRemainder} lbs`;
      }
      return `${imperialWeights.weightSt}st ${imperialWeights.weightLbsRemainder}lbs`;
    default:
      return `${Number(weightKg.toFixed(2))} kg`;
  }
};

export const displayNumberWith2Decimals = (number: number | string): string => {
  if (!isNumeric(number)) {
    return '';
  }
  return (Math.round((number as number) * 100) / 100).toFixed(2);
};

export const splitIntoWords = (
  string: string | undefined,
): '' | RegExpMatchArray | null => {
  if (!string || !string.length) {
    return '';
  }
  // this regex is to allow us to split the string into words, ridding of any whitespace, punctuation marks etc
  // see this tutorial: https://levelup.gitconnected.com/converting-a-string-to-camelcase-in-javascript-f88a646a22b4
  const regex =
    /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
  // returns an array of individual words from the string
  return string.match(regex);
};

export const camelCase = (string: string): string => {
  const wordsArray = splitIntoWords(string) || [];

  let result = '';

  for (let i = 0; i < wordsArray.length; i++) {
    const currentStr = wordsArray[i];

    let tempStr = currentStr.toLowerCase();

    if (i != 0) {
      // convert first letter to upper case (the word is in lowercase)
      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
    }

    result += tempStr;
  }

  return result;
};

/**
 * Converts an array of paths to a full array of every possible path when using locales
 *
 * e.g. Given the paths '/' and '/how-it-works' and the locales 'uk' and 'au',
 * it would return:
 * '/'
 * '/uk'
 * '/au'
 * '/how-it-works'
 * '/uk/how-it-works'
 * '/au/how-it-works'
 *
 * @param paths array of paths
 * @param locales array of country locales - like 'au' or 'uk'
 */
export const getPathsWithFullLocales = (
  paths: readonly string[],
  locales: readonly string[] = NEXTJS_LOCALES,
): string[] =>
  paths.flatMap(path => {
    const pathsWithLocales = locales.map(
      // If we encounter the base path, '/', do not append
      // it to the locale to avoid creating /uk/ and /au/
      locale => `/${locale}${path === '/' ? '' : path}`,
    );
    pathsWithLocales.push(path);
    return pathsWithLocales;
  });

/**
 * Checks whether an Express path begins with a country locale
 *
 * Includes a check for the edge case of the path being '/'
 *
 * @param path An Express path
 * @param locale A two-letter locale in lowercase (i.e. a country code, like 'uk')
 */
export const pathStartsWithLocale = (path: string, locale: string): boolean =>
  path.startsWith(`/${locale}/`) || path === `/${locale}`;

/**
 * Returns a locale from a provided path
 *
 * @param path An Express path
 */
export const getLocaleFromPath = (path: string): string =>
  NEXTJS_LOCALES.find(locale => pathStartsWithLocale(path, locale)) ||
  NEXTJS_DEFAULT_LOCALE;

/**
 * Escapes a string for use in a regular expression.
 * This is done by matching against all regex special characters in the incoming string and escaping them with \\.
 *
 * @param string - The string to escape
 * @returns The escaped string
 */
export const escapeRegex = (string: string): string =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
