import { getBMI } from '../../../../src/utils/numberUtils';
import {
  EligibilityCriteria,
  EligibilityResult,
  reduceEligibilityCriteria,
} from '../../../components/Quiz/QuizUtils';
import {
  HealthConditionsComorbiditiesType,
  OldQuizAnswersType,
} from '../../../types/quiz';
import {
  GLP1_VALID_COMORBIDITIES,
  meetsGLP1BMICriteriaWithComorbidity,
} from '../comorbidityEligibility';
import { getGLP1BaseCriteriaForOldQuizFlow } from './baseEligibility';

export const getWeightRelatedComorbiditiesForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    'healthConditions' | 'healthConditionsComorbidities'
  >,
): HealthConditionsComorbiditiesType[] => {
  const combinedHealthConditions = [
    ...(answers.healthConditions || []),
    ...(answers.healthConditionsComorbidities || []),
  ];

  return (
    (combinedHealthConditions.filter(condition =>
      GLP1_VALID_COMORBIDITIES.includes(
        condition as HealthConditionsComorbiditiesType,
      ),
    ) as HealthConditionsComorbiditiesType[]) || []
  );
};

/**
 * Determines if a user has a weight-related comorbidity based on their
 * answers to the healthConditions, healthConditionsComorbidities,
 * personalHabits and pregnancy questions in the quiz
 *
 * @param {Partial<OldQuizAnswersType>} answers - The user's quiz answers
 * @returns {boolean} true if the user has a weight-related comorbidity, false otherwise
 */
export const hasWeightRelatedComorbidityForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
  >,
): boolean => {
  const { personalHabits, pregnancy } = answers;

  const weightRelatedComorbidities =
    getWeightRelatedComorbiditiesForOldQuizFlow(answers);

  return (
    weightRelatedComorbidities.length > 0 ||
    personalHabits?.includes('lowMoodDueToWeight') ||
    (!!pregnancy && pregnancy.includes('problemsConceiving'))
  );
};

/**
 * Determines whether a user qualifies for the GLP1 programme if they have a BMI between
 * 27 and 30 and if they have a weight-related comorbidity
 *
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeWithComorbidityForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
  >,
  locale?: string,
): EligibilityResult => {
  const { weightKg, heightCm } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  /**
   * The Comorbidity criteria is the same as the base GLP1 criteria, except we
   * replace the requirement for a BMI >= 30 with a requirement for a BMI >= 27
   * and < 30, if accompanied by one of the weight-related comorbidities.
   */
  const glp1CriteriaWithoutBMI = getGLP1BaseCriteriaForOldQuizFlow(
    answers,
    locale,
  ).filter(criterion => criterion.key !== 'bmi');

  // An array of criteria to determine whether the user qualifies for GLP1
  const criteria: EligibilityCriteria[] = [
    ...glp1CriteriaWithoutBMI,
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteriaWithComorbidity(bmi),
    },
    {
      key: 'comorbidities',
      value: 'none',
      isValid: hasWeightRelatedComorbidityForOldQuizFlow(answers),
    },
  ];

  return reduceEligibilityCriteria(criteria);
};
