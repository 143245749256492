import { EligibilityResult } from '../../../components/Quiz/QuizUtils';
import { OldQuizAnswersType } from '../../../types/quiz';
import { qualifiesForGLP1ProgrammeWithoutExtendedEligibilityForOldQuizFlow } from './baseEligibility';
import { qualifiesForGLP1ProgrammeWithComorbidityForOldQuizFlow } from './comorbidityEligibility';
import { qualifiesForGLP1ProgrammeWithType2DiabetesForOldQuizFlow } from './type2DiabetesEligibility';

/**
 * Determines whether a user qualifies for the GLP1 programme, taking all their screening
 * answers into account.
 * @param {Partial<OldQuizAnswersType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    | 'age'
    | 'weightKg'
    | 'heightCm'
    | 'gender'
    | 'pregnancy'
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'diabetesStatus'
  >,
  locale?: string,
): EligibilityResult => {
  const qualifiesForGLP1 =
    qualifiesForGLP1ProgrammeWithoutExtendedEligibilityForOldQuizFlow(
      answers,
      locale,
    );

  /**
   * In the base GLP-1 eligibility criteria, users who have a BMI < 30
   * are not eligible for the programme. However, they may still meet the
   * extended eligibility criteria if they they have a 27 <= BMI < 30 and
   * present with a comorbidity.
   */
  const failedBMICriteria =
    Object.entries(qualifiesForGLP1.failingCriteria).length === 1 &&
    !!qualifiesForGLP1.failingCriteria?.bmi;

  if (failedBMICriteria) {
    return qualifiesForGLP1ProgrammeWithComorbidityForOldQuizFlow(
      answers,
      locale,
    );
  }

  /**
   * In the base GLP-1 eligibility criteria, users who have type 2 diabetes
   * are not eligible for the programme. However, they may still meet the
   * extended eligibility criteria if they manage their diabetes through
   * lifestyle or by taking Metformin.
   */
  const failedType2DiabetesCriteria =
    Object.entries(qualifiesForGLP1.failingCriteria).length === 1 &&
    qualifiesForGLP1.failingCriteria?.diabetesStatus === 'type2';

  const failedType2DiabetesAndBmiCriteria =
    Object.entries(qualifiesForGLP1.failingCriteria).length === 2 &&
    qualifiesForGLP1.failingCriteria?.diabetesStatus === 'type2' &&
    !!qualifiesForGLP1.failingCriteria?.bmi;

  // Users can go onto the GLP1 programme if they have bmi >= 27 instead
  // of bmi >= 30, so we need to check both conditions
  if (failedType2DiabetesCriteria || failedType2DiabetesAndBmiCriteria) {
    return qualifiesForGLP1ProgrammeWithType2DiabetesForOldQuizFlow(
      answers,
      locale,
    );
  }

  return qualifiesForGLP1;
};
